<template>
  <div>
    <header
        id="topnav"
        class="bg-white defaultscroll sticky"
    >
      <div class="container">
        <div>
          <router-link class="logo" to="/">
            <img id="logo-header" src="#" height="60" alt="" />
          </router-link>
        </div>
        <div class="buy-button">
          <a
              v-if="!!urlPlataform"
              :href="urlPlataform"
              target="_blank"
              class="btn mr-2"
              :class="{
                'btn-light': navLight === true,
                'btn-primary': navLight !== true,
              }"
          >
            Entrar
          </a>
          <b-dropdown
              class="btn-group dropdown-primary"
              text="Cadastrar"
              variant="primary"
          >
            <router-link to="/fornecedor" class="dropdown-item">
              <briefcase-icon class="fea icon-sm"></briefcase-icon> Fornecedor
            </router-link>
            <router-link to="/cidadao" class="dropdown-item">
              <user-icon class="fea icon-sm"></user-icon> Cidadão
            </router-link>
          </b-dropdown>
        </div>
        <div class="menu-extras">
          <div class="menu-item">
            <a
                class="navbar-toggle"
                @click="toggleMenu()"
                :class="{ open: isCondensed === true }"
            >
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </div>
        </div>

        <div id="navigation">
          <ul
              class="navigation-menu"
          >
            <li>
              <router-link to="/" class="side-nav-link-ref">Início</router-link>
            </li>
            <li>
              <router-link to="/processos" class="side-nav-link-ref">Processos</router-link>
            </li>
            <li>
              <router-link to="/planos" class="side-nav-link-ref">Planos</router-link>
            </li>
          </ul>
          <div class="buy-menu-btn d-none">
            <a
                v-if="!!urlPlataform"
                :href="urlPlataform"
                target="_blank"
                class="btn mr-2"
                :class="{
                'btn-light': navLight === true,
                'btn-primary': navLight !== true,
              }"
            >
              Entrar
            </a>
            <b-dropdown
                class="btn-group dropdown-primary"
                text="Cadastrar"
                variant="primary"
                dropright
            >
              <router-link to="/fornecedor" class="dropdown-item">
                <briefcase-icon class="fea icon-sm"></briefcase-icon> Fornecedor
              </router-link>
              <router-link to="/cidadao" class="dropdown-item">
                <user-icon class="fea icon-sm"></user-icon> Cidadão
              </router-link>
            </b-dropdown>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import {
  BriefcaseIcon,
  UserIcon,
} from "vue-feather-icons";

export default {
  data() {
    return {
      isCondensed: false,
    };
  },
  components: {
    BriefcaseIcon,
    UserIcon,
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
  },
  computed: {
    urlPlataform() {
      return this.$store.getters['layout/urlPlataform']
    },
  },
  mounted: () => {
    window.onscroll = function () {
      onwindowScroll();
    };

    function onwindowScroll() {
      if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
      ) {
        document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        document.getElementById("topnav").classList.remove("nav-sticky");
      }

      if (
          document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 100
      ) {
        document.getElementById("back-to-top").style.display = "inline";
      } else {
        document.getElementById("back-to-top").style.display = "none";
      }
    }

    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },

    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;

      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },
  },
};
</script>

