var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"bg-white defaultscroll sticky",attrs:{"id":"topnav"}},[_c('div',{staticClass:"container"},[_c('div',[_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('img',{attrs:{"id":"logo-header","src":"#","height":"60","alt":""}})])],1),_c('div',{staticClass:"buy-button"},[(!!_vm.urlPlataform)?_c('a',{staticClass:"btn mr-2",class:{
              'btn-light': _vm.navLight === true,
              'btn-primary': _vm.navLight !== true,
            },attrs:{"href":_vm.urlPlataform,"target":"_blank"}},[_vm._v(" Entrar ")]):_vm._e(),_c('b-dropdown',{staticClass:"btn-group dropdown-primary",attrs:{"text":"Cadastrar","variant":"primary"}},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/fornecedor"}},[_c('briefcase-icon',{staticClass:"fea icon-sm"}),_vm._v(" Fornecedor ")],1),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/cidadao"}},[_c('user-icon',{staticClass:"fea icon-sm"}),_vm._v(" Cidadão ")],1)],1)],1),_c('div',{staticClass:"menu-extras"},[_c('div',{staticClass:"menu-item"},[_c('a',{staticClass:"navbar-toggle",class:{ open: _vm.isCondensed === true },on:{"click":function($event){return _vm.toggleMenu()}}},[_vm._m(0)])])]),_c('div',{attrs:{"id":"navigation"}},[_c('ul',{staticClass:"navigation-menu"},[_c('li',[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":"/"}},[_vm._v("Início")])],1),_c('li',[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":"/processos"}},[_vm._v("Processos")])],1),_c('li',[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":"/planos"}},[_vm._v("Planos")])],1)]),_c('div',{staticClass:"buy-menu-btn d-none"},[(!!_vm.urlPlataform)?_c('a',{staticClass:"btn mr-2",class:{
              'btn-light': _vm.navLight === true,
              'btn-primary': _vm.navLight !== true,
            },attrs:{"href":_vm.urlPlataform,"target":"_blank"}},[_vm._v(" Entrar ")]):_vm._e(),_c('b-dropdown',{staticClass:"btn-group dropdown-primary",attrs:{"text":"Cadastrar","variant":"primary","dropright":""}},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/fornecedor"}},[_c('briefcase-icon',{staticClass:"fea icon-sm"}),_vm._v(" Fornecedor ")],1),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/cidadao"}},[_c('user-icon',{staticClass:"fea icon-sm"}),_vm._v(" Cidadão ")],1)],1)],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lines"},[_c('span'),_c('span'),_c('span')])
}]

export { render, staticRenderFns }