import * as types from "./mutation-types";

export default {
    [types.SET_THEME](state, payload) {
        state.settings.theme = payload
    },
    [types.SET_FAVICON](state, payload) {
        state.settings.favicon = payload
    },
    [types.SET_LOGO](state, payload) {
        state.settings.logo = payload
    },
    [types.SET_TITLE](state, payload) {
        state.settings.title = payload
    },
    [types.SET_URL_PLATAFORM](state, payload) {
        state.settings.url_plataform = payload
    },
    [types.SET_ORGANIZATION_NAME](state, payload) {
        state.settings.organization_name = payload
    },
    [types.SET_URL_WHATSAPP](state, payload) {
        state.settings.url_whatsapp = payload
    },
}