import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home')
  },
  {
    path: '/processos',
    name: 'processos',
    component: () => import('../views/ProcessList')
  },
  {
    path: '/planos',
    name: 'planos',
    component: () => import('../views/Plans')
  },
  {
    path: '/processos/:processId',
    name: 'processos.detalhes',
    component: () => import('../views/ProcessDetails'),
    props: true
  },
  {
    path: '/fornecedor',
    name: 'fornecedor',
    component: () => import('../views/ProviderRegister')
  },
  {
    path: '/cidadao',
    name: 'cidadao',
    component: () => import('../views/UserRegister')
  },
  {
    path: '/politica-de-privacidade',
    name: 'politica-de-privacidade',
    component: () => import('../views/PrivacyPolicy')
  },
  {
    path: '/termos-de-servico',
    name: 'termos-de-servico',
    component: () => import('../views/ServiceTerms')
  },
  {
    path: '*',
    component: () => import('../views/Error')
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
