import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.config.productionTip = false

import "../public/css/materialdesignicons.min.css";
import 'swiper/swiper-bundle.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'


import { BootstrapVue } from 'bootstrap-vue'
var VueScrollTo = require('vue-scrollto');
import Scrollspy from 'vue2-scrollspy';
import VueMasonry from 'vue-masonry-css'

Vue.use(VueScrollTo, {
  duration: 1000,
  easing: "ease"
})

Vue.use(BootstrapVue)
Vue.use(Scrollspy);
Vue.use(VueMasonry)
Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(VueReCaptcha, { siteKey: '6LcVrvQnAAAAAM3zzrZrKxPzaDNhLNRCSBz9fpmm' })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
