<template>
  <div id="app-component" class="d-none">
    <Navbar />

    <router-view />

    <Footer />

    <a class="btn-message-whatsapp d-none" target="_blank">
      <i style="margin-top:16px" class="fa-brands fa-whatsapp"></i>
    </a>

    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import {
  ArrowUpIcon
} from 'vue-feather-icons';

export default {
  components: {
    Navbar,
    Footer,
    ArrowUpIcon
  },
  mounted() {
    this.$store.dispatch("layout/ActionGetSettingsLocalStorage")
    this.$store.dispatch("layout/ActionSettings")
  },
}
</script>
<style lang="scss">
.grecaptcha-badge {
  visibility: hidden;
}
.swal2-title {
  font-size: 1em !important;
}
.btn-message-whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  left: 20px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow:  1px 1px 2px #888;
  z-index: 1000;
}
.btn-message-whatsapp:hover {
  color: #025B23FC;
}
</style>
