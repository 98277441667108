<template>
  <div>
    <footer class="footer">
      <div class="container">
        <div class="row">

          <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Links Úteis</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <router-link to="/processos" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> Processos
                </router-link>
              </li>
              <li>
                <router-link to="/planos" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> Planos
                </router-link>
              </li>
              <li>
                <router-link to="/fornecedor" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> Cadastro
                </router-link>
              </li>
              <li v-if="!!urlPlataform">
                <a :href="urlPlataform" target="_blank" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> Entrar
                </a>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head"></h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head"></h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
              </li>
            </ul>
          </div>

          <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <h5 class="text-light footer-head">Contato</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li><span class="font-weight-bold">WhatsApp:</span> (99) 98444-9559</li>
              <li><span class="font-weight-bold">E-mail:</span> contato@startgov.com.br</li>
            </ul>
            <p class="mt-4">
            </p>
          </div>

        </div>
      </div>
    </footer>
    <footer class="footer footer-bar">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-left">
              <p class="mb-0">
                © 2021 - {{ new Date().getFullYear() }}.
                <span class="font-italic">distribuido por</span>
                <a
                  href="https://www.startgov.com.br"
                  target="_blank"
                  class="text-reset"
                >
                  StartGov.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  MailIcon,
} from "vue-feather-icons";

export default {
  data() {
    return {};
  },
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MailIcon,
  },
  computed: {
    urlPlataform() {
      return this.$store.getters['layout/urlPlataform']
    },
  },
};
</script>