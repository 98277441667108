import * as types from "./mutation-types";
import BiddingSystemSettingService from "@/services/BiddingSystemSettingService";

export const ActionGetSettingsLocalStorage = ({dispatch}) => {
    const settings = JSON.parse(localStorage.getItem('settings'))

    if (settings !== null) {
        dispatch('ActionSetSettings', settings)
    }
}

export const ActionSettings = async ({dispatch}) => {
    const origin = window.location.host

    const response = await BiddingSystemSettingService.get(origin)
    const {data: settings} = response.data

    if (settings.length !== 0) {
        dispatch('ActionSetLocalStorage', settings)
        dispatch('ActionGetSettingsLocalStorage')
    }
}

export const ActionSetSettings = ({dispatch}, payload) => {
    dispatch('setTheme', payload.theme)
    dispatch('setTitle', payload)
    dispatch('setWhatsapp', payload.organization_name)
    dispatch('setLogo', payload.logo)
    dispatch('setFavicon', payload.favicon)
    dispatch('setUrlPlataform', payload.url_plataform)
    dispatch('setOrganizationName', payload.organization_name)
    dispatch('removeHiddenPage')
}

export const ActionSetLocalStorage = ({commit}, settings) => {
    localStorage.setItem('settings', JSON.stringify(settings))
}

export const setTitle = ({commit}, {title, organization_name}) => {
    commit(types.SET_TITLE, title)

    const el = document.querySelector('title')
    el.text = title + ' ' + organization_name
}

export const removeHiddenPage = () => {
    setTimeout(function() {
        const el = document.getElementById('app-component')
        el.classList.remove('d-none');
    }, 200)
}

export const setWhatsapp = ({commit}, organization_name) => {
    const url = `https://api.whatsapp.com/send?phone=5599984449559&text=Olá, estou com dúvidas sobre o Portal de Licitação da ${organization_name}`

    commit(types.SET_URL_WHATSAPP, url)

    const el = document.getElementsByClassName('btn-message-whatsapp')
    el[0].href = url
    el[0].classList.remove('d-none');
}

export const setUrlPlataform = ({commit}, url) => {
    commit(types.SET_URL_PLATAFORM, url)
}

export const setOrganizationName = ({commit}, name) => {
    commit(types.SET_ORGANIZATION_NAME, name)
}

export const setFavicon = ({commit}, path) => {
    commit(types.SET_FAVICON, path)

    const el = document.getElementById("favicon")
    el.setAttribute('href', path)
}

export const setLogo = ({commit}, path) => {
    commit(types.SET_LOGO, path)

    const el = document.getElementById('logo-header')
    el.setAttribute('src', path)
}

export const setTheme = ({commit}, theme) => {
    commit(types.SET_THEME, theme)

    document
        .getElementById("color-opt")
        .setAttribute("href", "./css/colors/" + theme + ".css")
}