import axios from 'axios'

const path = process.env.VUE_APP_BASE_API + 'biddingSystemSettings'

export default {
    get(origin) {
        return axios.get(`${path}`, {
            params: {
                origin: origin
            }
        })
    },
}