export default {
    settings: {
        title: null,
        organization_name: null,
        theme: null,
        logo: null,
        favicon: null,
        url_plataform: null,
        url_whatsapp: null,
    }
}