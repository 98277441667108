export const organizationName = ({settings}) => {
    return settings.organization_name
}
export const title = ({settings}) => {
    return settings.title
}
export const urlPlataform = ({settings}) => {
    return settings.url_plataform
}
export const urlWhatsapp = ({settings}) => {
    return settings.url_whatsapp
}